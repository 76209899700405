import React from "react";
import Result from "../images/results-circle.svg";
import Innovation from "../images/innovation-circle.svg";
import Collaboration from "../images/collaboration-circle.svg";

const approachDetails = [
  {
    title: "Collaboration",
    description:
      "Collaboration lies at the heart of everything we do, ensuring our solutions align with your unique needs.",
    image: Collaboration,
  },
  {
    title: "Innovation",
    description:
      "Innovation drives our strategies, allowing us to provide forward-thinking, impactful solutions.",
    image: Innovation,
  },
  {
    title: "Results",
    description:
      "Our focus on results guarantees tangible outcomes that exceed expectations.",
    image: Result,
  },
];

const OurApproach = () => {
  return (
    <section id="approach" className="bg-beige py-20">
      {/* Section Title */}
      <div className="text-center mb-16 px-4">
        <h2 className="text-4xl font-heading text-green-700">Our Approach</h2>
        <p className="text-lg text-gray-600 mt-4 max-w-3xl mx-auto leading-relaxed">
          At HaloEquities, we pride ourselves on blending expertise with innovation to craft solutions tailored to our clients' unique needs. Through collaboration, forward-thinking strategies, and a relentless focus on results, we turn vision into reality.
        </p>
      </div>

      {/* Approach Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 max-w-screen-xl mx-auto px-6">
        {approachDetails.map((detail, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center transform transition-transform hover:scale-105"
          >
            {/* Circular Image */}
            <div
              className="w-32 h-32 rounded-full bg-gradient-to-b from-gold-light to-gold-dark shadow-lg flex items-center justify-center mb-6 relative"
              style={{
                backgroundImage: `url('${detail.image}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>

            {/* Title */}
            <h3 className="text-xl font-heading text-green-700 mb-4">
              {detail.title}
            </h3>

            {/* Description */}
            <p className="text-sm text-gray-700 leading-relaxed">
              {detail.description}
            </p>
          </div>
        ))}
      </div>

      {/* Call to Action */}
      <div className="mt-16 text-center">
        <p className="text-lg text-gray-700 mb-4">
          Learn how our tailored strategies can elevate your investments.
        </p>
        <a href="#contact" className="px-6 py-3 text-lg font-paragraph font-bold text-white bg-transparent border border-gold rounded-full hover:bg-gold hover:text-black transition-all">
          Contact Us
        </a>
      </div>
    </section>
  );
};

export default OurApproach;
