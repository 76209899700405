import React from "react";
import CompanyAvatar from "../images/CompanyAvatar.jpg";
import EddieAvatar from "../images/EddieCompanyAvatar.PNG";

const AboutUs = () => {
  const team = [
    {
      name: "Michael Strickland",
      role: "Founder & CEO",
      img: CompanyAvatar,
    },
    {
      name: "Eddie Albornoz",
      role: "Founder & Director of Operations",
      img: EddieAvatar,
    },
  ];

  return (
    <section  id="about" className="bg-beige text-black">
  {/* More About Our Brand Section */}
  <div className="bg-[#F1A638] text-white py-10">
    <div className="container mx-auto text-center">
      <h2 className="text-4xl font-heading font-bold mb-4">More About Our Brand</h2>
      <p className="max-w-3xl mx-auto text-lg leading-relaxed">
        Tell people about who you are, your origin, your process, or your inspirations.
        Sound like you. Your story is what's going to set you apart.
      </p>
    </div>
  </div>

  {/* Meet the Team Section */}
  <div className="bg-green-700 text-white py-16">
    <div className="container mx-auto text-center mb-12">
      <h3 className="text-3xl font-heading font-bold">Meet the Team</h3>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 px-8 max-w-screen-lg mx-auto">
      {team.map((member, index) => (
        <div
          key={index}
          className="bg-beige p-6 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105"
        >
          <img
            src={member.img}
            alt={member.name}
            className="w-24 h-24 mx-auto rounded-full mb-4 border-4 border-green-700"
          />
          <h4 className="text-xl font-heading font-bold">{member.name}</h4>
          <p className="text-sm font-paragraph">{member.role}</p>
        </div>
      ))}
    </div>
  </div>
</section>

  );
};

export default AboutUs;
