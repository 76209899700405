import React from "react";
import PrivateLending from "../images/private-lending.jpg";
import FixFlip from "../images/fix&flip.jpg";
import Hospitality from "../images/hospitality.jpg";
import Tech from "../images/TechStartUp.jpg";


const investmentData = [
  {
    title: "Private Lending",
    hoverDescription:
      "Private Lending offers high-security short-term loans, ensuring reliable returns on real estate investments.",
    image: PrivateLending,
  },
  {
    title: "Fix and Flips",
    hoverDescription:
      "Fix and Flips are a strategy for purchasing, renovating, and selling properties for profit.",
    image: FixFlip,
  },
  {
    title: "Tech Startups",
    hoverDescription:
      "Invest in innovative startups driving the future of technology and growth.",
    image: Tech,
  },
  {
    title: "Hospitality Investments",
    hoverDescription:
      "Hospitality Investments target niche markets, focusing on high-yield boutique hotels.",
    image: Hospitality,
  },
];

const Services = () => {
  return (
    <section id="services" className="bg-green-800 py-16">
      <div className="max-w-screen-lg mx-auto px-6">
        <h2 className="text-h2 font-heading font-bold text-beige text-center mb-4">
          Our Investment Focus
        </h2>
        <p className="text-p1 font-paragraph text-beige text-center mb-12">
          Explore our key areas: Private Lending, Fix and Flip, Boutique
          Hospitality, and Tech Startups.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {investmentData.map((item, index) => (
            <div
              key={index}
              className="relative group bg-green-700 rounded-full overflow-hidden shadow-lg hover:rounded-xl transition-all duration-300"
            >
              {/* Image */}
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-64 object-cover rounded-full group-hover:rounded-none transition-all duration-300"
              />

              {/* Overlay on hover */}
              <div className="absolute inset-0 bg-black bg-opacity-75 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-all duration-300">
                <h3 className="text-h3 font-heading text-gold mb-2">
                  {item.title}
                </h3>
                <p className="text-p2 font-paragraph text-beige text-center">
                  {item.hoverDescription}
                </p>
              </div>

              {/* Title & Price */}
              <div className="text-center mt-4">
                <h3 className="text-h4 font-heading text-beige">
                  {item.title}
                </h3>
                <p className="text-p2 font-paragraph text-gold">
                  {item.price}
                </p>
                <p className="text-p3 font-paragraph text-beige">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
