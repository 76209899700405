import React from "react";

const Footer = () => {
  return (
    <footer className="bg-green-800 text-white py-10">
      <div className="container mx-auto text-center px-4">
        <p className="text-2xl font-playfair font-bold text-gold">
          HaloEquities
        </p>
        <ul className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6 mt-4 font-roboto">
          <li>
            <a href="#services" className="hover:text-gold">
              Services
            </a>
          </li>
          <li>
            <a href="#approach" className="hover:text-gold">
              Our Approach
            </a>
          </li>
          <li>
            <a href="#about" className="hover:text-gold">
              About Us
            </a>
          </li>
          <li>
            <a href="#contact" className="hover:text-gold">
              Contact
            </a>
          </li>
        </ul>
        <p className="text-sm text-muted mt-6">
          &copy; 2024 HaloEquities. All rights reserved.{" "}
          <a href="/privacy" className="hover:text-gold">
            Privacy Policy
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
