import React, { useState } from "react";
import HeroImage from "../images/HaloMainHero.jpg";

const Hero = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <section
      className="relative h-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${HeroImage})` }}
    >
      {/* Transparent Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>

      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-20">
        <div className="flex justify-between items-center max-w-screen-lg mx-auto px-6 py-4">
          <a href="/" className="text-3xl font-heading font-bold text-white">
            HaloEquities
          </a>
          {/* Hamburger Menu */}
          <button
            className="text-white text-3xl sm:hidden focus:outline-none"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            ☰
          </button>
          {/* Links */}
          <ul className={`sm:flex space-x-6 text-white text-lg font-paragraph ${menuOpen ? "block" : "hidden"} sm:block absolute sm:relative bg-black sm:bg-transparent w-full sm:w-auto left-0 top-16 sm:top-0 p-4 sm:p-0`}>
            <li>
              <a href="#services" className="hover:text-gold block sm:inline">
                Services
              </a>
            </li>
            <li>
              <a href="#about" className="hover:text-gold block sm:inline">
                About
              </a>
            </li>
            <li>
              <a href="#contact" className="hover:text-gold block sm:inline">
                Contact
              </a>
            </li>
          </ul>
          {/* Join Button */}
          <a
            href="#contact"
            className="hidden sm:block px-6 py-3 text-sm font-bold text-gold border border-gold rounded-full hover:bg-gold hover:text-black transition-all"
          >
            JOIN OUR JOURNEY
          </a>
        </div>
      </nav>

      {/* Hero Content */}
      <div className="relative z-10 flex flex-col justify-center items-start text-left max-w-screen-lg mx-auto px-6 h-full">
        <h1 className="text-3xl sm:text-h1 font-heading font-bold text-white leading-tight mb-4">
          Welcome to Halo Equities
        </h1>
        <h2 className="text-xl sm:text-h2 italic font-heading text-gold mb-6">
          Your Investment Guardian Angel
        </h2>
        <p className="text-base sm:text-p1 font-paragraph text-white leading-relaxed mb-8">
          At Halo Equities, we specialize in transforming opportunities into
          lasting success. Whether you're seeking secure private lending
          options, high-yield real estate investments, or innovative startup
          ventures, we’re here to guide you every step of the way.
        </p>
        <a href='#approach' className="px-6 py-3 text-lg font-paragraph font-bold text-white bg-transparent border border-gold rounded-full hover:bg-gold hover:text-black transition-all">
          Learn More
        </a>
      </div>

      {/* Scrolling Company Name */}
      <div className="absolute bottom-0 w-full py-4">
        <div className="overflow-hidden whitespace-nowrap">
          <p className="text-h1 font-heading font-bold text-white animate-scroll">
            HaloEquities HaloEquities HaloEquities HaloEquities
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
